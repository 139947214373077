import React from "react"

export const GetFoundByBuyers = () => (
    <span
      id="hs_cos_wrapper_thomas4_newsletter_signup"
      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_raw_html"
      data-hs-cos-general-type="widget"
      data-hs-cos-type="raw_html"
      data-global-widget-id="5499833001"
    >
      <section className="footer-cta inverted-section" data-thdoc="S26">
        <div className="container">
          <div className="footer-cta__body">
            <figure className="footer-cta__logo">
              <img
                src="https://cdn40.thomasnet.com/img40/thomas-logo-blue-vertical.svg"
                alt="Thomas Logo"
              />
            </figure>
            <header className="section-header--inverted">
              <h2 className="section-header__primary">
                Get Found By Buyers &amp; Engineers
              </h2>
              <p className="section-header__secondary">
                Connect with the most active and qualified network of B2B buyers
                on the world's leading platform for product discovery and
                supplier selection.{" "}
              </p>
            </header>
            <div className="footer-cta__btn">
              <a
                href="https://business.thomasnet.com/get-listed-on-thomasnet"
                className="btn btn-primary btn-xl"
              >
                Get Listed
              </a>
            </div>
          </div>
        </div>
      </section>
    </span>
    
)