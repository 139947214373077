import React from "react";
import "./spinner.scss";

const Spinner = ({ ...props }) => (
  <div className="spinner" {...props}>
    <div className="rect1" />
    <div className="rect2" />
    <div className="rect3" />
  </div>
);

export { Spinner };
