import React from "react";
import SEO from "components/seo";
import { EmailPreferences } from "../components/emailPreferences/EmailPreferences";
import { decodeQueryParameter, parseUrlSearch } from "utils/queryParameters";
import { GetFoundByBuyers } from "components/footer-cta/getFoundByBuyers";

const EmailPreferencesPage = ({ location }: { location: any }) => {
  const params = parseUrlSearch(location.search);
  const email = decodeQueryParameter(params["email"]);

  return (
    <>
      <SEO title="Email Preferences" />
      <section className="page-utility">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="https://www.thomasnet.com">Home</a>
              </li>
              <li className="breadcrumb-item">
                <span className="breadcrumb_last">Email Preferences</span>
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section className="page-content page-content--single-column">
        <div className="container container--sm">
          <header className="page-header">
            <h1 className="page-header__title font-weight-normal">
              Email Preferences
            </h1>
          </header>
          <EmailPreferences email={email} />
        </div>
      </section>
      <GetFoundByBuyers />

    </>
  );
};

export default EmailPreferencesPage;
