import React, { useContext } from "react";
import { CurrentUser } from "./CurrentUser";
import { Subscriptions } from "./Subscriptions";
import { EmailPreferencesContainer } from "./EmailPreferencesContainer";
import SavedNotification from "components/savedNotification/savedNotification";
import { AuthContext } from "auth/AuthContext";

interface EmailPreferencesProps {
  email: string;
}

const EmailPreferences = ({ email }: EmailPreferencesProps) => {
  const { user } = useContext(AuthContext);

  function getEmail() {
    if (email) {
      return email;
    }
    if (user && user.email) {
      return user.email;
    }
    return "";
  }

  return (
    <EmailPreferencesContainer email={getEmail()}>
      {({
        user,
        save,
        saved,
        handleUserChange,
        resetUser,
        loading,
        displayError,
        disableButton
      }) => {
        return (
          <div className="content-card content-card--pattern mb-4">
            <CurrentUser
              user={user}
              onResetUser={resetUser}
              onSetUser={(email: string) => {
                handleUserChange({
                  email
                });
              }}
              loading={loading}
              displayError={displayError}
            />

            <hr className="thick-line-break" />

            <Subscriptions
              user={user}
              userSubscriptions={user ? user.subscriptions : []}
              onSubscriptionsChange={(subscriptions: any[]) => {
                handleUserChange({
                  subscriptions
                });
              }}
            />

            <div
              style={{ marginTop: "2rem", display: "flex", flexFlow: "row" }}
            >
              <button
                disabled={disableButton}
                className="btn btn-primary"
                type="button"
                style={{}}
                onClick={save}
              >
                Save Preferences
              </button>
              {saved && <SavedNotification />}
            </div>
          </div>
        );
      }}
    </EmailPreferencesContainer>
  );
};

export { EmailPreferences };
