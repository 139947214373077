import { PersonalizeTiu } from "components/newsletters/PersonalizeTiu";
import React from "react";
import ReactModal from "react-modal";
import { Newsletter } from "./models";
import styles from "./Newsletter.module.scss";
import "../../styles/react-modal.scss";
import { categoriesMap, topicsMap } from "../newsletters/data";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";

const SAVE_CATEGORIES_MUTATION = gql`
  mutation($categories: [String], $topics: [String], $email: String) {
    saveCategories(categories: $categories, topics: $topics, email: $email) {
      success
      error
    }
  }
`;

ReactModal.setAppElement("#___gatsby");

interface SubscriptionProps {
  newsletter: Newsletter;
  isSubscribed: boolean;
  onChange: any;
  user: any;
}

interface State {
  isModalOpen: boolean;
  saved: boolean;
  changed: boolean;
  checkedTopics: Map<string, boolean>;
  checkedCategories: Map<string, boolean>;
  allTopicsChecked: boolean;
  allCategoriesChecked: boolean;
}

function categoriesToMap(categories: string) {
  return new Map(
    categories.split("").map((catCode: string) => {
      let category = categoriesMap.find(catM => catM.encoded === catCode);
      return [category ? category.value : "", true];
    })
  );
}

function topicsToMap(topics: string[]) {
  return new Map(
    topics.map((topic: string) => {
      return [topic, true];
    })
  );
}

export class NewsletterItem extends React.Component<SubscriptionProps, State> {
  constructor(props: SubscriptionProps) {
    super(props);

    const { topics = [], categories = "" } = props.user
      ? props.user.categoriesAndTopics
      : {};

    this.state = {
      isModalOpen: false,
      saved: false,
      changed: false,
      checkedCategories: categoriesToMap(categories),
      checkedTopics: topicsToMap(topics),
      allTopicsChecked: false,
      allCategoriesChecked: false
    };
  }

  componentDidMount = () => {
    this.checkIfAllSelected(categoriesMap, topicsMap)
  };

  checkIfAllSelected = (categoriesMap, topicsMap) => {
    let topicsChecked = false;
    let categoriesChecked = false;

    if (this.state.checkedTopics.size === topicsMap.length) {
      let values = Array.from(this.state.checkedTopics.values());
      let isAllTopChecked = values.some(t => t === false);
      if (!isAllTopChecked) {
        topicsChecked = true;
      }
    }
    if (this.state.checkedCategories.size === categoriesMap.length) {
      let values = Array.from(this.state.checkedCategories.values());
      let isAllCatChecked = values.some(t => t === false);
      if (!isAllCatChecked) {
        categoriesChecked = true;
      }
    }

    this.setState({
      ...this.state,
      allTopicsChecked: topicsChecked,
      allCategoriesChecked: categoriesChecked
    });

  }

  render() {
    const { newsletter, isSubscribed, onChange, user } = this.props;
    const {
      isModalOpen,
      saved,
      changed,
      checkedTopics,
      checkedCategories,
      allCategoriesChecked,
      allTopicsChecked
    } = this.state;

    return (
      <div className="subscription">
        <div className={styles.flexRowContainer}>
          <input
            style={{ marginTop: "5px", marginRight: "10px" }}
            type="checkbox"
            checked={isSubscribed}
            id={newsletter.id}
            onChange={() => {
              if (user && user.email) {
                this.setState({
                  isModalOpen: !isSubscribed
                });
              }

              onChange({
                subscriptionId: newsletter.id,
                checked: !isSubscribed
              });
            }}
          />
          <div className={styles.flexColContainer}>
            <div className={styles.flexRowContainer}>
              <label
                className="font-size-base font-weight-bold"
                htmlFor={newsletter.id}
                style={{
                  marginBottom: 0,
                }}
              >
                {newsletter.title}
              </label>
              <div className={styles.subscriptionContainer}>
                {newsletter.personalizeComponent &&
                  isSubscribed &&
                  (user && user.email) && (
                    <span style={{ marginLeft: 14 }}>
                      <a
                        href="#personalize"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({
                            isModalOpen: true
                          });
                        }}
                        style={{ textDecoration: "underline", outline: "none" }}
                      >
                        Personalize
                      </a>

                      <Mutation mutation={SAVE_CATEGORIES_MUTATION}>
                        {(mutate: any) => {
                          return (
                            <ReactModal
                              className="Modal__Bootstrap modal-dialog modal-size"
                              closeTimeoutMS={300}
                              isOpen={isModalOpen}
                              contentLabel="Personalize"
                              onRequestClose={() =>
                                this.setState({
                                  isModalOpen: false
                                })
                              }
                              overlayClassName="ReactModal__Overlay"
                            >
                              <div
                                className="modal-content"
                                style={{
                                  maxHeight: "calc(100vh - 50px)",
                                  overflow: "auto"
                                }}
                              >
                                <div
                                  className="modal-body"
                                >
                                  <button
                                    type="button"
                                    className="close"
                                    onClick={() =>
                                      this.setState({
                                        isModalOpen: false
                                      })
                                    }
                                  >
                                    <span aria-hidden="true">&times;</span>
                                    <span className="sr-only">Close</span>
                                  </button>

                                  <PersonalizeTiu
                                    // initialSelections={user}
                                    topicsMap={topicsMap}
                                    categoriesMap={categoriesMap}
                                    checkedCategories={checkedCategories}
                                    checkedTopics={checkedTopics}
                                    allCategoriesChecked={allCategoriesChecked}
                                    allTopicsChecked={allTopicsChecked}
                                    handleChange={(e: any) => {
                                      const item = e.target.name;
                                      const isChecked = e.target.checked;
                                      const group = e.target.getAttribute(
                                        "radiogroup"
                                      );
                                      if (group === "cats") {
                                        this.setState(prevState => ({
                                          checkedCategories: prevState.checkedCategories.set(
                                            item,
                                            isChecked
                                          ),
                                          changed: true
                                        }), () => this.checkIfAllSelected(categoriesMap, topicsMap));
                                      } else {
                                        this.setState(prevState => ({
                                          checkedTopics: prevState.checkedTopics.set(
                                            item,
                                            isChecked
                                          ),
                                          changed: true
                                        }), () => this.checkIfAllSelected(categoriesMap, topicsMap));
                                      }
                                    }
                                    }
                                    toggleSelectAll={(e: any) => {
                                      const group = e.target.getAttribute(
                                        "radiogroup"
                                      );
                                      if (group === "select-all-tops") {
                                        if (!this.state.allTopicsChecked) {
                                          this.setState(prevState => ({
                                            checkedTopics: new Map(
                                              topicsMap.map((t: any) => {
                                                return [t.value, true];
                                              })
                                            ),
                                            allTopicsChecked: true,
                                            changed: true
                                          }));
                                        } else {
                                          this.setState(prevState => ({
                                            checkedTopics: new Map(
                                              topicsMap.map((t: any) => {
                                                return [t.value, false];
                                              })
                                            ),
                                            allTopicsChecked: false,
                                            changed: true
                                          }));
                                        }
                                      } else {
                                        if (!this.state.allCategoriesChecked) {
                                          this.setState(prevState => ({
                                            checkedCategories: new Map(
                                              categoriesMap.map((t: any) => {
                                                return [t.value, true];
                                              })
                                            ),
                                            allCategoriesChecked: true,
                                            changed: true
                                          }));
                                        } else {
                                          this.setState(prevState => ({
                                            checkedCategories: new Map(
                                              categoriesMap.map((t: any) => {
                                                return [t.value, false];
                                              })
                                            ),
                                            allCategoriesChecked: false,
                                            changed: true
                                          }));
                                        }
                                      }
                                    }}
                                  />
                                </div>
                                <div className={styles.modalFooter}>
                                  <div><hr /></div>
                                  <div>
                                    <button
                                      type="button"
                                      style={{ marginRight: "1rem" }}
                                      className="btn btn-primary"
                                      onClick={e => {
                                        if (changed) {
                                          mutate({
                                            variables: {
                                              email: user.email,
                                              categories: Array.from(
                                                checkedCategories.entries()
                                              )
                                                .filter(arrr => arrr[1] === true)
                                                .reduce((acc: string[], curr) => {
                                                  return acc.concat(curr[0]);
                                                }, []),
                                              topics: Array.from(
                                                checkedTopics.entries()
                                              )
                                                .filter(arrr => arrr[1] === true)
                                                .reduce((acc: string[], curr) => {
                                                  return acc.concat(curr[0]);
                                                }, [])
                                            }
                                          }).then(() => {
                                            // show saved message
                                            this.setState({
                                              saved: true
                                            });

                                            // delay closing of modal for visual confirmation of Saved
                                            setTimeout(() => {
                                              this.setState({
                                                isModalOpen: false
                                              });
                                            }, 500);
                                          });
                                        } else {
                                          this.setState({
                                            isModalOpen: false
                                          });
                                        }
                                      }}
                                    >
                                      Save Selections
                                  </button>
                                    <button
                                      type="button"
                                      className="btn"
                                      style={{ border: "2px solid #dfe6ec", color: "#A4ACB3" }}
                                      onClick={() =>
                                        this.setState({
                                          isModalOpen: false
                                        })
                                      }
                                    >
                                      Revert
                                  </button>
                                    {saved && (
                                      <span style={{ marginLeft: "1rem" }}>
                                        {" "}
                                        <svg
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="icon"
                                        >
                                          <title>ico-check-mark</title>
                                          <path
                                            d="M27.604 3.372a1.155 1.155 0 0 0-1.628.112L11.614 19.958 6.477 17.15a1.19 1.19 0 0 0-1.503 1.784l5.457 6.865a2.473 2.473 0 0 0 4.014-.198l.179-.278L27.816 4.867a1.153 1.153 0 0 0-.212-1.495z"
                                            fill="green"
                                            fillRule="nonzero"
                                          />
                                        </svg>
                                        {"  "}Your preferences have been saved
                                    </span>
                                    )}
                                  </div>

                                </div>
                              </div>
                            </ReactModal>
                          );
                        }}
                      </Mutation>
                    </span>
                  )}
              </div>
            </div>
            <p className="font-size-sm" style={{ margin: 0 }}>
              {newsletter.description}
            </p>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}
