const topicsMap = [
  {
    name: "Supply Chain",
    value: "supply_chain"
  },
  {
    name: "Engineering & Design",
    value: "eng_design"
  },
  {
    name: "Sales & Marketing",
    value: "sales_marketing"
  },
  {
    name: "Thomas Index",
    value: "thomas_index"
  },
  {
    name: "New Products",
    value: "new_products"
  },
  {
    name: "Manufacturing & Innovation",
    value: "manufact_innovate"
  },
  {
    name: "Industry Trends",
    value: "industry_trends"
  },
  {
    name: "Career & Workforce",
    value: "career_workforce"
  },
  {
    name: "Company News",
    value: "company_news"
  }
];
const categoriesMap = [
  {
    name: "Adhesives & Sealants",
    encoded: "1",
    value: "adhesives_sealants"
  },

  {
    name: "Agricultural and Farming Products",
    encoded: "2",
    value: "agricultural_farming"
  },

  {
    name: "Architectural and Civil Engineering Products",
    encoded: "3",
    value: "architectural_civil_engineering"
  },

  {
    name: "Automatic ID",
    encoded: "4",
    value: "automatic_id"
  },

  {
    name: "Chemicals & Gases",
    encoded: "5",
    value: "chemicals_gases"
  },

  {
    name: "Cleaning Products & Equipment",
    encoded: "6",
    value: "cleaning_products_equipment"
  },

  {
    name: "Communication Systems & Equipment",
    encoded: "7",
    value: "communication_systems_equipment"
  },

  {
    name: "Computer Hardware & Peripherals",
    encoded: "8",
    value: "computer_hardware_peripherals"
  },

  {
    name: "Construction Equipment and Supplies",
    encoded: "9",
    value: "construction_equipment_supplies"
  },

  {
    name: "Controls & Controllers",
    encoded: "W",
    value: "controls_controllers"
  },

  {
    name: "Custom Manufacturing Services",
    encoded: "U",
    value: "custom_manufacturing_services"
  },

  {
    name: "Display & Presentation Equipment",
    encoded: "a",
    value: "display_presentation_equipment"
  },

  {
    name: "Electrical Equipment & Systems",
    encoded: "b",
    value: "electrical_equipment_systems"
  },

  {
    name: "Electronic Components & Devices",
    encoded: "c",
    value: "electronic_components_devices"
  },

  {
    name: "Explosives,  Armaments, and Weaponry",
    encoded: "d",
    value: "explosive_armaments_weaponry"
  },

  {
    name: "Fasteners & Hardware",
    encoded: "e",
    value: "fasteners_hardware"
  },

  {
    name: "Fluid & Gas Flow Equipment",
    encoded: "f",
    value: "fluid_gas_flow_equipment"
  },

  {
    name: "Food Processing & Preparation",
    encoded: "g",
    value: "food_processing_preparation"
  },

  {
    name: "Health, Medical, & Dental Supplies and Equipment",
    encoded: "i",
    value: "health_medical_dental_supplies_equipment"
  },

  {
    name: "HVAC",
    encoded: "j",
    value: "hvac"
  },

  {
    name: "Labels Tags Signage & Equipment",
    encoded: "l",
    value: "labels_tags_signage_equipment"
  },

  {
    name: "Laboratory and Research Supplies and Equipment",
    encoded: "m",
    value: "laboratory_research_supplies_equipment"
  },

  {
    name: "Lubricants",
    encoded: "n",
    value: "lubricants"
  },

  {
    name: "Machinery & Machining Tools",
    encoded: "o",
    value: "machinery_machining_tools"
  },

  {
    name: "Material Handling & Storage",
    encoded: "p",
    value: "material_handling_storage"
  },

  {
    name: "Materials",
    encoded: "q",
    value: "materials"
  },

  {
    name: "Material Processing",
    encoded: "V",
    value: "material_processing"
  },

  {
    name: "Mechanical Components and Assemblies",
    encoded: "r",
    value: "mechanical_components_assemblies"
  },

  {
    name: "Mechanical Power Transmission",
    encoded: "s",
    value: "mechanical_power_transmission"
  },

  {
    name: "Mining, Oil Drilling, Refining Products & Equipment",
    encoded: "t",
    value: "mining_oil_drilling_refining"
  },

  {
    name: "Mounting & Attaching Products",
    encoded: "u",
    value: "mounting_attaching_products"
  },

  {
    name: "Non-Industrial Products",
    encoded: "v",
    value: "non_industrial_products"
  },

  {
    name: "Optics & Photonics",
    encoded: "A",
    value: "optics_photonics"
  },

  {
    name: "Packaging Products & Equipment",
    encoded: "B",
    value: "packaging_products_equipment"
  },

  {
    name: "Paints & Coatings",
    encoded: "C",
    value: "paints_coatings"
  },

  {
    name: "Plant Furnishings & Accessories",
    encoded: "D",
    value: "plant_furnishings_accessories"
  },

  {
    name: "Portable Tools",
    encoded: "E",
    value: "portable_tools"
  },

  {
    name: "Printing & Duplicating Equipment",
    encoded: "F",
    value: "printing_duplicating_equipment"
  },

  {
    name: "Retail and Sales Equipment",
    encoded: "G",
    value: "retail_sales_equipment"
  },

  {
    name: "Robotics",
    encoded: "H",
    value: "robotics"
  },

  {
    name: "Safety & Security Equipment",
    encoded: "I",
    value: "safety_security_equipment"
  },

  {
    name: "Sensors Monitors & Transducers",
    encoded: "J",
    value: "sensors_monitors_transducers"
  },

  {
    name: "Services",
    encoded: "K",
    value: "services"
  },

  {
    name: "Software",
    encoded: "L",
    value: "software"
  },

  {
    name: "Test & Measuring Instruments",
    encoded: "M",
    value: "test_measuring_instruments"
  },

  {
    name: "Textile Industry Products",
    encoded: "N",
    value: "textile_industry_products"
  },

  {
    name: "Thermal & Heating Equipment",
    encoded: "O",
    value: "thermal_heating_equipment"
  },

  {
    name: "Timers & Clocks",
    encoded: "P",
    value: "timers_clocks"
  },

  {
    name: "Transportation Industry Products",
    encoded: "Q",
    value: "transportation_industry_products"
  },

  {
    name: "Vision Systems",
    encoded: "R",
    value: "vision_systems"
  },

  {
    name: "Waste Management & Waste Handling Equipment",
    encoded: "S",
    value: "waste_handling_equipment"
  },

  {
    name: "Welding Equipment & Supplies",
    encoded: "T",
    value: "welding_equipment_supplies"
  }
];

export { topicsMap, categoriesMap };
