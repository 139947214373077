import React from "react";
import { Link } from "gatsby";
import { Spinner } from "../spinner/spinner";

interface NewUserProps {
  onSetUser: (email: string) => any;
  displayError: boolean;
}

const SignIn = () => {
  return (
    <p
      style={{
        fontSize: "16px",
        color: "red",
        marginTop: "5px",
        fontWeight: "100"
      }}
    >
      Please enter a valid Email Address
    </p>
  );
};

const NewUser = ({ onSetUser, displayError }: NewUserProps) => {
  const borderColor = displayError ? "red" : "#A4ACB3";

  return (
    <>
      <p>
        Subscribe to stay up to date on industry trends, product announcements
        &amp; insights.
        <br />
        Already subscribed?{" "}
        <a href="https://account.thomasnet.com/login.html">Sign in</a> to manage
        your preferences.
      </p>

      <form style={{ marginBottom: "1em", display: "block" }}>
        <div style={{ maxWidth: 400 }}>
          <label
            className="font-size-sm"
            style={{
              display: "block",
              color: `${displayError} ? "red" : "black"`
            }}
            htmlFor="email"
          >
            Email
          </label>
          <input
            className="form-control input-lg"
            style={{ borderColor: `${borderColor}` }}
            id="email"
            type="text"
            onChange={event => {
              onSetUser(event.currentTarget.value);
              // setEmail(event.currentTarget.value);
            }}
          />
          {displayError && <SignIn />}
        </div>
      </form>
    </>
  );
};

interface ExistingUserProps {
  user: any;
  onResetUser: (event: any) => any;
  loading?: boolean;
  displayError?: boolean;
}

const ExistingUser = ({ user, onResetUser }: ExistingUserProps) => (
  <p>
    <strong>{user.email}</strong> is currently subscribed to the following
    emails and notifications.{" "}
    <Link to="/email-preferences/" onClick={onResetUser}>
      Not you?
    </Link>
  </p>
);

type CurrentUserProps = ExistingUserProps & NewUserProps;

const CurrentUser = ({
  user,
  onSetUser,
  onResetUser,
  loading,
  displayError
}: CurrentUserProps) => {
  return (
    <div style={{ position: "relative" }}>
      {loading && (
        <Spinner style={{ position: "absolute", top: 0, right: 0 }} />
      )}

      {user && user.id ? (
        <ExistingUser user={user} onResetUser={onResetUser} />
      ) : (
          <NewUser onSetUser={onSetUser} displayError={displayError} />
        )}
    </div>
  );
};

export { CurrentUser };
