import React from "react";
import { Query, Mutation } from "react-apollo";
import { gql } from "apollo-boost";

interface RenderProp {
  (args: any): JSX.Element;
}

interface EmailPreferencesStateContainerProps {
  defaultUser: any;
  children: RenderProp;
}

interface EmailPreferencesStateContainerState {
  user: any;
  saved: boolean;
  displayError: boolean;
  disableButton: boolean;
}

const REGEX_EMAIL = /^\S+@\S+$/g;

export const SAVE_SUBSCRIPTIONS_MUTATION = gql`
  mutation($subscriptions: [SubscriptionInput], $email: String) {
    saveSubscriptions(subscriptions: $subscriptions, email: $email) {
      success
      userId
      error
    }
  }
`;

class EmailPreferencesStateContainer extends React.Component<
  EmailPreferencesStateContainerProps,
  EmailPreferencesStateContainerState
  > {
  public constructor(props: EmailPreferencesStateContainerProps) {
    super(props);

    this.state = {
      user: props.defaultUser,
      saved: false,
      displayError: false,
      disableButton: true
    };
  }

  private handleUserChange = (data: { [field: string]: any }) => {
    const { user } = this.state;
    this.setState({
      user: { ...user, ...data },
      saved: false,
      displayError: false,
      disableButton: false
    });
  };

  private checkForValidEmail = () => {
    const { user } = this.state;
    if (!user || !user.email) return false;
    return !user.email.match(REGEX_EMAIL) ? false : true;
  };

  private onSave = (userId: string) => {
    const { user } = this.state;
    if (!user) {
      return;
    }

    this.setState({
      ...this.state,
      saved: true,
      user: { ...this.state.user, id: userId }
    });
  };

  private handleError = () => {
    this.setState({ ...this.state, displayError: true });
  };

  private resetUser = () => {
    this.setState({ ...this.state, user: null, saved: false });
  };

  public render() {
    const { children } = this.props;
    const { user, saved, displayError, disableButton } = this.state;
    return children({
      user,
      onSave: this.onSave,
      saved,
      displayError,
      disableButton,
      handleError: this.handleError,
      handleUserChange: this.handleUserChange,
      resetUser: this.resetUser,
      checkForValidEmail: this.checkForValidEmail
    });
  }
}

class EmailPreferencesQuery extends Query<{ user: any }, { email: String }> { }

export const EmailPreferencesContainer = ({
  children,
  email
}: {
  children: RenderProp;
  email: string;
}) => {
  return (
    <EmailPreferencesQuery
      query={gql`
        query GetUser($email: String!) {
          user(email: $email) {
            id
            email
            firstName
            lastName
            subscriptions {
              newsletterId
              lastModified
              status
            }
            categoriesAndTopics {
              categories
              topics
            }
          }
        }
      `}
      variables={{ email: email }}
    >
      {({ loading, error, data }) => {
        return (
          <EmailPreferencesStateContainer
            key={data && data.user ? data.user.id : null}
            defaultUser={data ? data.user : null}
          >
            {({
              user,
              onSave,
              saved,
              displayError,
              disableButton,
              handleError,
              handleUserChange,
              resetUser,
              checkForValidEmail
            }) => {
              return (
                <Mutation mutation={SAVE_SUBSCRIPTIONS_MUTATION}>
                  {(save: any) =>
                    children &&
                    children({
                      loading: loading && email,
                      error,
                      user,
                      save: () => {
                        if (checkForValidEmail()) {
                          const subscriptions = user.subscriptions || [];
                          const results = subscriptions.map(
                            ({ __typename, ...rest }: any) => rest
                          );
                          const email = user.email;
                          save({
                            variables: { email, subscriptions: results }
                          }).then(({ data: { saveSubscriptions } }: any) => {
                            const {
                              success,
                              userId,
                              error
                            } = saveSubscriptions;
                            onSave(userId);
                          });
                        } else {
                          handleError();
                        }
                      },
                      saved,
                      handleUserChange,
                      resetUser,
                      displayError,
                      disableButton
                    })
                  }
                </Mutation>
              );
            }}
          </EmailPreferencesStateContainer>
        );
      }}
    </EmailPreferencesQuery>
  );
};
