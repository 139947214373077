import React from "react";

interface Props {
  topicsMap: any;
  categoriesMap: any;
  checkedCategories: Map<string, boolean>;
  checkedTopics: Map<string, boolean>;
  allCategoriesChecked: boolean;
  allTopicsChecked: boolean;
  handleChange: Function;
  toggleSelectAll: Function;
}

const PersonalizeTiu = ({
  topicsMap,
  categoriesMap,
  checkedCategories,
  checkedTopics,
  allCategoriesChecked,
  allTopicsChecked,
  handleChange,
  toggleSelectAll
}: Props) => {
  return (
    <div>
      <div style={{ marginBottom: "2em" }}>
        <h3>Personalize your Thomas Industry Update</h3>
        <p>
          We want to send you stories that are just right for you. Tell us about
          your topics and categories of interest below:
        </p>
      </div>

      <h4>Topics you are interested in:</h4>

      <div style={{ fontWeight: 500 }}>
        <input
          id="select-all-topics"
          radioGroup="select-all-tops"
          type="checkbox"
          className="xtopt"
          style={{ marginRight: 7 }}
          onChange={e => toggleSelectAll(e)}
          checked={allTopicsChecked}
        />
        <label htmlFor="select-all-topics">Select All</label>
      </div>
      <ul className="modal__unordered-list">
        {topicsMap.map((c: any) => (
          <li key={c.value} style={{ marginLeft: "18px", lineHeight: "2" }}>
            <label
              htmlFor={c.value}
              style={{
                fontWeight: "normal",
                lineHeight: "1.5"
              }}
            >
              <input
                id={c.value}
                type="checkbox"
                className="xtopt"
                radioGroup="tops"
                style={{
                  marginRight: 4,
                  marginTop: "15px",
                  marginLeft: "-18px"
                }}
                name={c.value}
                checked={checkedTopics.get(c.value) || false}
                onChange={e => handleChange(e)}
              />

              {c.name}
            </label>
          </li>
        ))}
      </ul>

      <h4>Categories you are interested in:</h4>
      <div style={{ fontWeight: 500 }}>
        <input
          id="select-all-categories"
          radioGroup="select-all-cats"
          type="checkbox"
          className="xtopt"
          style={{ marginRight: 7 }}
          onChange={e => toggleSelectAll(e)}
          checked={allCategoriesChecked}
        />
        <label
          htmlFor="select-all-categories"
        >
          Select All
        </label>
      </div>
      <ul className="modal__unordered-list" style={{ marginBottom: "5rem" }}>
        {categoriesMap.map((c: any) => (
          <li key={c.value} style={{ marginLeft: "18px", lineHeight: "2" }}>
            <label
              htmlFor={c.value}
              style={{
                fontWeight: "normal",
                lineHeight: "1.5"
              }}
            >
              <input
                type="checkbox"
                className="xtopt"
                style={{
                  marginRight: 4,
                  marginTop: "15px",
                  marginLeft: "-18px"
                }}
                id={c.value}
                name={c.value}
                radioGroup="cats"
                checked={checkedCategories.get(c.value) || false}
                onChange={e => handleChange(e)}
              />
              {c.name}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { PersonalizeTiu };
//   constructor(props: Props) {
//     super(props);

// const { topics, categories } = props.initialSelections.categoriesAndTopics;

// this.state = {
//   checkedCategories: new Map(
//     categories.split("").map((catCode: string) => {
//       let category = categoriesMap.find(catM => catM.encoded === catCode);
//       return [category && category.value, true];
//     })
//   ),
//   checkedTopics: new Map(
//     topics.map((topic: string) => {
//       return [topic, true];
//     })
//   )
// };

//     // initialize the parents components with the data
//     this.props.onUpdateTopics(this.state.checkedTopics, true);
//     this.props.onUpdateCategories(this.state.checkedCategories, true);
//   }

//   handleChange = (e: any) => {
//     const item = e.target.name;
//     const isChecked = e.target.checked;
//     const group = e.target.getAttribute("radiogroup");

//     if (group === "cats") {
//       this.setState(
//         prevState => ({
//           checkedCategories: prevState.checkedCategories.set(item, isChecked)
//         }),
//         this.props.onUpdateCategories(this.state.checkedCategories, false)
//       );
//     } else {
//       this.setState(
//         prevState => ({
//           checkedTopics: prevState.checkedTopics.set(item, isChecked)
//         }),
//         this.props.onUpdateTopics(this.state.checkedTopics, false)
//       );
//     }
//   };
