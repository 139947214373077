import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { UserSubscription, Newsletter } from "./models";
import { NewsletterItem } from "./Newsletter";

function userSubscriptionsWithUpdates(
  userSubscriptions: UserSubscription[] = [],
  idsToUpdate: string[],
  status: string,
  lastModified: string
) {
  return userSubscriptions.map(userSubscription => {
    // Skip records that do not match.
    if (!idsToUpdate.includes(userSubscription.newsletterId)) {
      return userSubscription;
    }

    return {
      ...userSubscription,
      status,
      lastModified
    };
  });
}

function userSubscriptionsWithInserts(
  userSubscriptions: UserSubscription[] = [],
  idsToUpdate: string[],
  status: string,
  lastModified: string
) {
  const idsToBeInserted = idsToUpdate.filter(
    id => !userSubscriptions.map(r => r.newsletterId).includes(id)
  );

  return userSubscriptions.concat(
    idsToBeInserted.map(newsletterId => ({
      newsletterId,
      status,
      lastModified
    }))
  );
}

interface HandlerFunction<T> {
  (data: T[]): void;
}

interface SubscriptionsProps {
  onSubscriptionsChange: HandlerFunction<UserSubscription>;
  userSubscriptions: UserSubscription[];
  user: any;
}

export class Subscriptions extends React.Component<SubscriptionsProps, {}> {
  public onSubscriptionsChange: HandlerFunction<UserSubscription>;

  public constructor(props: SubscriptionsProps) {
    super(props);
    this.onSubscriptionsChange = props.onSubscriptionsChange;
  }

  private toggleSelectAll = (subscriptionIds: string[], checked: boolean) => {
    this.onSubscriptionsChange(
      this.upsertSubscriptions({
        subscriptionIds,
        checked
      })
    );
  };

  private isAllChecked = (newsletters: Newsletter[]): boolean => {
    return (
      newsletters && !newsletters.some(({ id }) => !this.isUserSubscribed(id))
    );
  };

  private isUserSubscribed = (targetId: string): boolean => {
    const { userSubscriptions = [] } = this.props;

    return userSubscriptions.some(
      ({ newsletterId, status }) =>
        newsletterId === targetId && status === "Subscribed"
    );
  };

  public upsertSubscriptions = ({
    subscriptionIds,
    checked
  }: {
    subscriptionIds: string[];
    checked: boolean;
  }): UserSubscription[] => {
    const { userSubscriptions = [] } = this.props;
    const status = checked ? "Subscribed" : "Unsubscribed";
    const lastModified = new Date().toISOString();

    const results = userSubscriptionsWithUpdates(
      userSubscriptions,
      subscriptionIds,
      status,
      lastModified
    );

    return userSubscriptionsWithInserts(
      results,
      subscriptionIds,
      status,
      lastModified
    );
  };

  private handleSubscribeChange = ({
    subscriptionId,
    checked
  }: {
    subscriptionId: string;
    checked: boolean;
  }) => {
    this.onSubscriptionsChange(
      this.upsertSubscriptions({
        subscriptionIds: [subscriptionId],
        checked
      })
    );
  };

  public render() {
    return (
      <StaticQuery
        query={graphql`
          query NewslettersQuery {
            thomasnet {
              newsletters {
                id
                title
                description
                personalizeComponent
              }
            }
          }
        `}
        render={({ thomasnet }) => {
          const { newsletters = [] }: { newsletters: Newsletter[] } = thomasnet;

          return (
            <form>
              <div>
                <label
                  className="font-size-sm"
                  htmlFor="select-all"
                  style={{ fontWeight: 400, marginBottom: 0 }}
                >
                  <input
                    style={{ marginTop: "15px", marginRight: "10px" }}
                    type="checkbox"
                    id="select-all"
                    checked={this.isAllChecked(newsletters)}
                    onChange={event =>
                      this.toggleSelectAll(
                        newsletters.map(({ id }) => id),
                        event.target.checked
                      )
                    }
                  />
                  Select All
                </label>
              </div>

              <hr style={{ marginTop: "1.25rem", marginBottom: "1.25rem" }} />

              {newsletters &&
                newsletters.map(newsletter => (
                  <NewsletterItem
                    user={this.props.user}
                    key={newsletter.id}
                    newsletter={newsletter}
                    isSubscribed={this.isUserSubscribed(newsletter.id)}
                    onChange={this.handleSubscribeChange}
                  />
                ))}
            </form>
          );
        }}
      />
    );
  }
}
